import Module from './Module';
import dialogPolyfill from '../../../../../node_modules/dialog-polyfill';

export default class ModuleModal extends Module {
	constructor(el) {
		super(el);

		//-- Properties
		//---------------------------------------------------------
		this.elDialog = null;
		this.elDialogWrapper = null;
		this.elOpenTrigger = null;
		this.elCloseButton = null;
		this.elContent = null;
		this.elCancelElements = null;
		this.elPreviousFocus = null;

		this.options = null;
		this.customClasses = null;

		this.keyCodes = {
			enter: 13,
			spacebar: 32,
			arrowUp: 38,
			arrowDown: 40,
			tab: 9,
			escape: 27,
		};
	}

	//-- Methods
	//--------------------------------------------------------------
	init(elContent, options = {}) {
		this.options = options;

		// test if Module is builded upon jQuery, if so transforms object into vanilla object
		if (jQuery) {
			if (this.dom.el instanceof jQuery) {
				this.dom.el = this.dom.el[0];
			}

			if (this.dom.body instanceof jQuery) {
				this.dom.body = this.dom.body[0];
			}
		}

		if (this.dom.el !== undefined) {
			// if is initiated on an html element with data-module
			if (this.dom.el.hasAttribute('data-modal-content')) {
				this.elContent = $(this.dom.el.data('modal-content'))[0]; // as HTML element
			}

			this.elOpenTrigger = this.dom.el;
		} else {
			this.elContent = elContent;
			this.elOpenTrigger = this.options.trigger
				? this.options.trigger
				: null;
		}

		if (!this.elContent) {
			console.warn('There is no content to display in the modal');
			return;
		}

		this.elCancelElements = this.elContent.querySelectorAll(
			'[data-modal-action~="close"]'
		);
		this.customClasses = options.classes ? options.classes : '';
		this.openCallback = options.openCallback
			? options.openCallback
			: function () {};
		this.closeCallback = options.closeCallback
			? options.closeCallback
			: function () {};

		this.createModalDialog();
		this.bindEvent();

		// add the dialog to the end of the body
		this.dom.body.appendChild(this.elDialog);
	}

	createModalDialog() {
		// create the dialog element
		this.elDialog = document.createElement('dialog');
		this.elDialog.classList.add('modal-dialog');

		if (this.elContent.dataset.maxWidth) {
			this.elDialog.setAttribute(
				'style',
				'max-width:' + this.elContent.dataset.maxWidth + ';'
			);
		}

		if (this.customClasses) {
			this.elDialog.classList.add(this.customClasses);
		}

		dialogPolyfill.registerDialog(this.elDialog);

		// create the dialog wrapper element
		this.elDialogWrapper = document.createElement('div');
		this.elDialogWrapper.classList.add('dialog-wrapper');
		this.elDialogWrapper.setAttribute('tabindex', '-1');
		this.elDialogWrapper.appendChild(this.elContent);
		this.elContent.classList.remove('hidden');
		this.elDialog.appendChild(this.elDialogWrapper);

		// create the close butotn element
		this.elCloseButton = document.createElement('button');
		this.elCloseButton.classList.add('close-button');
		this.elCloseButton.innerHTML = `<span class="sr-only">${
			this.elContent.dataset.closeLabel || 'Close Modal default'
		}</span>`;
		this.elDialog.appendChild(this.elCloseButton);
	}

	bindEvent() {
		// handle the open
		if (this.elOpenTrigger) {
			this.elOpenTrigger.addEventListener(
				'click',
				function (e) {
					e.preventDefault();
					this.open(e.currentTarget);
				}.bind(this)
			);
		}

		// handle the backdrop close
		this.elDialog.addEventListener(
			'click',
			function (e) {
				if (e.target === this.elDialog) {
					this.close();
				}
			}.bind(this)
		);

		// handle the close button
		this.elCloseButton.addEventListener(
			'click',
			function (e) {
				this.close();
			}.bind(this)
		);

		// handle the escape key
		document.addEventListener(
			'keydown',
			function (e) {
				if (e.keyCode === this.keyCodes.escape) {
					this.close();
				}
			}.bind(this)
		);

		// handle the closing elements
		Array.prototype.forEach.call(
			this.elCancelElements,
			function (item) {
				item.addEventListener('click', this.close.bind(this));
			},
			this
		);
	}

	open(elFocus) {
		this.elPreviousFocus = elFocus || document.activeElement;

		if (this.elOpenTrigger) {
			this.elOpenTrigger.setAttribute('aria-expanded', 'true');
		}

		this.dom.body.classList.add('opened-dialog');
		this.dom.body.parentElement.classList.add('opened-dialog');

		this.elDialog.showModal();
		this.openCallback();
	}

	close(elForceFocus) {
		if (this.elOpenTrigger) {
			this.elOpenTrigger.removeAttribute('aria-expanded');
		}

		this.dom.body.classList.remove('opened-dialog');
		this.dom.body.parentElement.classList.remove('opened-dialog');

		this.elDialog.close();

		if (elForceFocus) {
			elForceFocus.focus();
		} else {
			this.elPreviousFocus.focus();
		}

		this.closeCallback();
	}
}
