import Module from './Module';
import ModuleModal from './ModuleModal';

export default class ModuleExitIntent extends Module {
	constructor(el) {
		super(el);

		this.requirementsMet = true;
		if (!this.requirementsMet) return;

		//-- properties
		this.delayInitModule = null;
		this.delayInactivityBeforeFire = null;
		this.inactivityTimeout = null;
		this.exitIntentModal = null;
		this.isShown = false;
		this.cookieFrequency = null;
		this.cookieValue = null;
		this.cookieName = 'exitIntent';
		this.cookieExpiryDays = !this.dom.el
			? 1
			: this.dom.$el.dataset.cookieExpirydays;
	}

	init() {
		if (!this.dom.el) return;

		this.delayInitModule = this.dom.$el.dataset.delayInit || 3000;
		this.delayInactivityBeforeFire =
			this.dom.$el.dataset.delayInactivity || 30000;

		this.cookieFrequency = this.dom.$el.dataset.cookieExpiry;
		this.cookieValue = this.dom.$el.dataset.cookieName;

		// binding 'this' to the functions
		this.openModal = this.openModal.bind(this);
		this.setInactivityTimer = this.setInactivityTimer.bind(this);
		this.bindEvents = this.bindEvents.bind(this);
		this.exitIntentModal = new ModuleModal();
		this.exitIntentModal.init(this.dom.$el, {
			classes: 'exit-intent',
		});

		setTimeout(this.bindEvents, this.delayInitModule);
	}

	bindEvents() {
		this.dom.body.addEventListener('mouseleave', this.openModal);
		this.setInactivityTimer();

		// set inactivity events
		document.addEventListener('mousemove', this.setInactivityTimer);
		document.addEventListener('click', this.setInactivityTimer);
		document.addEventListener('keydown', this.setInactivityTimer);
		document.addEventListener('keyup', this.setInactivityTimer);
	}

	disableAllListeners() {
		this.dom.body.removeEventListener('mouseleave', this.openModal);
		document.removeEventListener('mousemove', this.setInactivityTimer);
		document.removeEventListener('click', this.setInactivityTimer);
		document.removeEventListener('keydown', this.setInactivityTimer);
		document.removeEventListener('keyup', this.setInactivityTimer);
	}

	setInactivityTimer() {
		clearTimeout(this.inactivityTimeout);

		this.inactivityTimeout = setTimeout(
			function () {
				this.openModal();
			}.bind(this),
			this.delayInactivityBeforeFire
		);
	}

	openModal() {
		if (this.isShown) return;

		this.isShown = true;
		this.disableAllListeners();

		if (this.isDataDisplayable()) {
			this.setCookie(this.cookieValue, this.cookieFrequency);

			this.exitIntentModal.open();
			document.querySelector('.exitIntentbtn').click();
		}
	}

	isDataDisplayable() {
		if (this.cookieName !== '') {
			if (this.cookieFrequency == 'session') {
				return !sessionStorage.getItem(this.cookieName);
			} else {
				return !this.isCookiePresent(this.cookieName);
			}
		}
		return true;
	}

	setCookie(value, expiry) {
		if (this.cookieName !== '') {
			if (expiry == 'session') {
				sessionStorage.setItem(this.cookieName, value);
			} else {
				document.cookie = `${
					this.cookieName
				}=${value}; expires=${this.getExpireFromNow(
					this.cookieExpiryDays
				)};`;
			}
		}
	}

	isCookiePresent(cookieName) {
		if (
			document.cookie
				.split(';')
				.some((item) => item.trim().startsWith(`${cookieName}=`))
		) {
			return true;
		} else {
			return false;
		}
	}

	getExpireFromNow(numberOfDay) {
		let date = new Date();
		date.setTime(+date + numberOfDay * 86400000);
		return date.toGMTString();
	}
}
